import React from 'react';
import ContactUs from '../components/ContactUs';


export default function Pioneers(props) {

    const scrolldiv = () => {
        var elem = document.getElementById("ele");
        elem.scrollIntoView();
    }

    return (
        <>
            <section className="bg-pioneer banner-section">
                <div className="container">
                    <div className='row justify-content-center'>
                        <div className='col-md-10 col-lg-12'>
                        <div>
                            <img alt="tools" className="flag-image" src="/assets/images/flag.png" />
                        </div>
                            <h1 className='font-500 pioneer-heading'>
                                Pioneer <span className='font-500 dm-italic'>(n)</span>
                            </h1>
                            <p className='pioneer-description'>a person or group that originates or helps open up a new line of thought or activity or a new method or  technical development.</p>
                        </div>
                    </div>
                      {/*
                        <div className='row justify-content-center'>
                            
                            <div className='col-md-10'>
                                <h3 className='mt-3 pioneer-heading-3'>Forge the Future with the </h3>
                                <h3 className='pioneer-heading-3'><span className='dm-italic'>CIVIT Pioneers Program</span></h3>
                              
                                <div>
                                
                                <p style={{color:'white'}}>We are redefining the way we live, learn, work and interact with one another, paving the way for a more sustainable & regenerative future for all.</p>
                                <h5 className='text-colorsconder'>APPLICATIONS OPEN</h5>
                                <div class="justify-content-center d-flex">
    
                                    <button onClick={scrolldiv} className="btn mt-3" type="button" style={{background:'#BBC6F4'}}>
                                        Become a Pioneer
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                        >
                                            <path
                                                d="M11.0303 3.21967C10.7374 2.92678 10.2626 2.92678 9.96967 3.21967C9.67678 3.51256 9.67678 3.98744 9.96967 4.28033L11.0303 3.21967ZM15.75 9L16.2803 9.53033C16.421 9.38968 16.5 9.19891 16.5 9C16.5 8.80109 16.421 8.61032 16.2803 8.46967L15.75 9ZM9.96967 13.7197C9.67678 14.0126 9.67678 14.4874 9.96967 14.7803C10.2626 15.0732 10.7374 15.0732 11.0303 14.7803L9.96967 13.7197ZM2.25 8.25C1.83579 8.25 1.5 8.58579 1.5 9C1.5 9.41421 1.83579 9.75 2.25 9.75L2.25 8.25ZM9.96967 4.28033L15.2197 9.53033L16.2803 8.46967L11.0303 3.21967L9.96967 4.28033ZM15.2197 8.46967L9.96967 13.7197L11.0303 14.7803L16.2803 9.53033L15.2197 8.46967ZM15.75 8.25L2.25 8.25L2.25 9.75L15.75 9.75V8.25Z"
                                                fill="#0A1F44"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                </div>
                            </div>
                        </div>
                         */}
                </div>
            </section>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-3 mt-3 ps-lg-0 ">
                            <div className='text-lg-start '>
                                <img alt="tools" className="w-100 rounded-3" src="/assets/images/why.png" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-3 mt-3  align-self-center">
                            <div className='text-start p-lg-5 p-md-3 p-3'>
                                <h1 className='section-heading-main'>Why Become a CIVIT Pioneer?</h1>
                                <p className='mt-3 mb-0 section-description'>The CIVIT Pioneers Program is designed for those who dare to dream and do. For the visionaries who see beyond the horizon and the innovators eager to sculpt the future. As a Pioneer, you’ll receive exclusive access, influence, and the ability to steer the development of the CIVIT ecosystem. You are the first to move the needle, setting the course for a legacy of innovation and impact.</p>
                                <button onClick={scrolldiv} className="btn mt-5" type="button" style={{background:'#4640BA',color:'white'}}>
                                    Join the Movement
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                    >
                                        <path
                                            d="M11.0303 3.21967C10.7374 2.92678 10.2626 2.92678 9.96967 3.21967C9.67678 3.51256 9.67678 3.98744 9.96967 4.28033L11.0303 3.21967ZM15.75 9L16.2803 9.53033C16.421 9.38968 16.5 9.19891 16.5 9C16.5 8.80109 16.421 8.61032 16.2803 8.46967L15.75 9ZM9.96967 13.7197C9.67678 14.0126 9.67678 14.4874 9.96967 14.7803C10.2626 15.0732 10.7374 15.0732 11.0303 14.7803L9.96967 13.7197ZM2.25 8.25C1.83579 8.25 1.5 8.58579 1.5 9C1.5 9.41421 1.83579 9.75 2.25 9.75L2.25 8.25ZM9.96967 4.28033L15.2197 9.53033L16.2803 8.46967L11.0303 3.21967L9.96967 4.28033ZM15.2197 8.46967L9.96967 13.7197L11.0303 14.7803L16.2803 9.53033L15.2197 8.46967ZM15.75 8.25L2.25 8.25L2.25 9.75L15.75 9.75V8.25Z"
                                            fill="#fff"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="virtula-community-section mt-lg-0 mt-md-3 mt-3 p-130">
                <div className="container">
                    <div className="row">
                        <div className=" col-12 pioneer-future-title-col flex-column d-flex">
                            <div>
                                <img alt="tools" width={50} className="" src="/assets/images/flag.png" />
                            </div>
                            <p className="subtitle text-center mb-0 font-700">
                                Benefits
                            </p>
                            <h4 className="title text-center mb-0">
                                Benefits of Being a CIVIT Pioneer
                            </h4>
                            <div className="dark-card-col">
                                <div className="dark-card text-start d-flex flex-column ">
                                    <div className="dark-card-header ">
                                        <img src="/assets/images/profile.png" width={50} className="" alt="tools" />

                                    </div>
                                    <h6 className="mb-0 font-500">
                                        Profile Feature
                                    </h6>
                                    <p className="desc mb-0">
                                        Highlight your journey, achievements, and vision in our "Meet the
                                        Pioneers" series, celebrating your role in the CIVIT community.
                                    </p>
                                </div>
                                <div className="dark-card text-start d-flex flex-column ">
                                    <div className="dark-card-header ">
                                        <img src="/assets/images/profile2.png" width={50} className="" alt="tools" />

                                    </div>
                                    <h6 className="mb-0 font-500">
                                        Exclusive Early Access
                                    </h6>
                                    <p className="desc mb-0">
                                        Be among the first to explore and shape CIVIT's features,
                                        from the CIVIT Passport to Circles and Journeys, providing invaluable feedback that
                                        shapes the platform.
                                    </p>
                                </div>
                                <div className="dark-card text-start d-flex flex-column ">
                                    <div className="dark-card-header ">
                                        <img src="/assets/images/profile1.png" width={50} className="" alt="tools" />

                                    </div>
                                    <h6 className="mb-0 font-500">
                                        Lifetime 50% Discount
                                    </h6>
                                    <p className="desc mb-0">
                                        Enjoy a lifetime discount of 50% on all CIVIT events,
                                        merchandise, and membership fees, as a token of our gratitude for your pioneering
                                        spirit.
                                    </p>
                                </div>

                            </div>


                            <div className="dark-card-col">
                                <div className="dark-card text-start d-flex flex-column ">
                                    <div className="dark-card-header ">
                                        <img src="/assets/images/profile4.png" width={50} className="" alt="tools" />

                                    </div>
                                    <h6 className="mb-0 font-500">
                                        Access to Private Pioneers Circle
                                    </h6>
                                    <p className="desc mb-0">
                                        Join an exclusive online community where pioneers
                                        share ideas, opportunities, and experiences, fostering deep connections and
                                        collaboration.
                                    </p>
                                </div>
                                <div className="dark-card text-start d-flex flex-column ">
                                    <div className="dark-card-header ">
                                        <img src="/assets/images/profile5.png" width={50} className="" alt="tools" />

                                    </div>
                                    <h6 className="mb-0 font-500">
                                        Mastermind Sessions
                                    </h6>
                                    <p className="desc mb-0">
                                        Participate in intimate brainstorming sessions with the CIVIT
                                        team and fellow Pioneers, influencing feature development and strategic direction.
                                    </p>
                                </div>
                                <div className="dark-card text-start d-flex flex-column ">
                                    <div className="dark-card-header ">
                                        <img src="/assets/images/profile6.png" width={50} className="" alt="tools" />

                                    </div>
                                    <h6 className="mb-0 font-500">
                                        Networking Events
                                    </h6>
                                    <p className="desc mb-0">
                                        Receive invitations to elite networking events, connecting you with
                                        like-minded innovators, creators, and leaders in the CIVIT community.
                                    </p>
                                </div>

                            </div>


                            <div className="dark-card-col">
                                <div className="dark-card text-start d-flex flex-column ">
                                    <div className="dark-card-header ">
                                        <img src="/assets/images/profile7.png" width={50} className="" alt="tools" />

                                    </div>
                                    <h6 className="mb-0 font-500">
                                        CIVIT Nation Building Summit
                                    </h6>
                                    <p className="desc mb-0">
                                        An exclusive invitation to join us at a breathtaking destination, co-creating and experiencing the essence of shared living firsthand.
                                    </p>
                                </div>
                                <div className="dark-card text-start d-flex flex-column ">
                                    <div className="dark-card-header ">
                                        <img src="/assets/images/profile8.png" width={50} className="" alt="tools" />

                                    </div>
                                    <h6 className="mb-0 font-500">
                                        Deep Connection & Collaboration
                                    </h6>
                                    <p className="desc mb-0">
                                        Forge lasting relationships with a community of
                                        trailblazers, collaborating on projects that pave the way for a new future
                                    </p>
                                </div>
                                <div className="dark-card text-start d-flex flex-column ">
                                    <div className="dark-card-header ">
                                        <img src="/assets/images/profile9.png" width={50} className="" alt="tools" />

                                    </div>
                                    <h6 className="mb-0 font-500">
                                        Exclusive CIVIT Pioneer Status
                                    </h6>
                                    <p className="desc mb-0">
                                        Earn your place as a foundational member of the
                                        CIVIT Nation, celebrated for your pioneering spirit and contributions
                                    </p>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </section>
            <section className='Apply-become p-2'>
                <div className="container bgcolorwhite rounded-2">
                    <div className='p-4'>
                        <img src="/assets/images/userpic.png" width={50} className="mb-2" alt="tools" />

                        <h1 className='mb-5'>How to Apply to become a Pioneer</h1>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mt-lg-0 mt-md-4 mt-4">
                                <div className='becomecard p-3 rounded-3'>
                                    <div className='bgcolorwhiteone'><h4 className=''>1</h4></div>
                                    <h4 className=''> <span className='dm-italic textcolorpurpl'>Manifesto</span>  Alignment</h4>
                                    <p>Share with us how you <br className="d-none d-lg-block "></br>  embody the CIVIT Manifesto in <br className="d-none d-lg-block "></br>  your life
                                        and work.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mt-lg-0 mt-md-4 mt-4">
                                <div className='becomecard p-3 rounded-3'>
                                    <div className='bgcolorwhiteone'><h4 className=''>2</h4></div>
                                    <h4 className=''> <span className='dm-italic textcolorpurpl'>Vision</span>  Contribution</h4>
                                    <p>Describe how you see yourself <br className="d-none d-lg-block "></br>  contributing to the CIVIT
                                        ecosystem <br className="d-none d-lg-block "></br>  & the future of community living.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mt-lg-0 mt-md-4 mt-4">
                                <div className='becomecard p-3 rounded-3'>
                                    <div className='bgcolorwhiteone'><h4 className=''>3</h4></div>
                                    <h4 className=''> <span className='dm-italic textcolorpurpl'>Innovative</span>  Spirit </h4>
                                    <p>Highlight your experiences as an <br className="d-none d-lg-block "></br>  innovator, creator, or community <br className="d-none d-lg-block "></br>
                                        builder.</p>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div>
                            <h3 className='mt-3'>Selection Process</h3>
                            <p>Our selection process is designed to identify individuals who are not just passionate about
                                change but are actively working <br class="br-lg-block br-md-none br-none" /> towards it. We look for alignment with our core values, a
                                demonstrated history of innovation, and a commitment to the <br className="d-none d-lg-block "></br>  CIVIT ethos. Successful
                                applicants will be notified and invited to an onboarding session</p>
                            <div className='justify-content-center d-flex'>

                                <button onClick={scrolldiv} className="btn mt-3" type="button" style={{background:'#4640BA',color:'white'}}>
                                    Start Application
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                    >
                                        <path
                                            d="M11.0303 3.21967C10.7374 2.92678 10.2626 2.92678 9.96967 3.21967C9.67678 3.51256 9.67678 3.98744 9.96967 4.28033L11.0303 3.21967ZM15.75 9L16.2803 9.53033C16.421 9.38968 16.5 9.19891 16.5 9C16.5 8.80109 16.421 8.61032 16.2803 8.46967L15.75 9ZM9.96967 13.7197C9.67678 14.0126 9.67678 14.4874 9.96967 14.7803C10.2626 15.0732 10.7374 15.0732 11.0303 14.7803L9.96967 13.7197ZM2.25 8.25C1.83579 8.25 1.5 8.58579 1.5 9C1.5 9.41421 1.83579 9.75 2.25 9.75L2.25 8.25ZM9.96967 4.28033L15.2197 9.53033L16.2803 8.46967L11.0303 3.21967L9.96967 4.28033ZM15.2197 8.46967L9.96967 13.7197L11.0303 14.7803L16.2803 9.53033L15.2197 8.46967ZM15.75 8.25L2.25 8.25L2.25 9.75L15.75 9.75V8.25Z"
                                            fill="#fff"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className='bg-journey '>
                <div className=''>
                    <img alt="tools" width={300} className="mb-3 rounded-3 mt-5" src="/assets/images/boysimg.png" />
                </div>
                <div className="container pb-5">
                    <div>
                        <h6 className='text-coloropurpl textstyle'>The journey ahead</h6>
                        <h1 className='text-colorneyblue font-500'>What You’ll Unlock as a CIVIT Pioneer</h1>
                        <p className='text-colorneyblue'>Embarking on the CIVIT Pioneers Program is not just a commitment—it's a transformative adventure designed to catalyze change in the way we live, connect, and thrive. Over the course of a year, you will be immersed in a dynamic ecosystem of innovation, collaboration, and community building. Here’s what you can expect:</p>
                    </div>
                    <div className='row'>
                        <div className="col-md-6 mt-3">
                            <div className='cardjourney  rounded-3 text-start position-relative h-100'>
                                <div>
                                    <img alt="tools" width={5} className="position-absolute mt-4" src="/assets/images/angle.png" />
                                </div>
                                <div className='p-4'>
                                    <h4 className='text-colorneyblue'>Weekly Mastermind Sessions</h4>
                                    <h6 className='text-coloropurpl textstyle'>Weekly</h6>
                                    <p>Dive deep into the heart of innovation with exclusive mastermind  <br className="d-none d-lg-block "></br>  sessions.
                                        Led by industry leaders and CIVIT's founding team,  <br className="d-none d-lg-block "></br>  these sessions are an opportunity to
                                        share insights, challenge <br className="d-none d-lg-block "></br>  ideas, and co-create solutions that will shape the future of
                                        CIVIT. <br className="d-none d-lg-block "></br>  Each session is designed to foster deep connections and <br className="d-none d-lg-block "></br>  collaborative
                                        brainstorming among Pioneers.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className='cardjourney  rounded-3 text-start position-relative h-100'>
                                <div>
                                    <img alt="tools" width={5} className="position-absolute mt-4" src="/assets/images/angle.png" />
                                </div>
                                <div className='p-4'>
                                    <h4 className='text-colorneyblue'>Annual CIVIT Summit</h4>
                                    <h6 className='text-coloropurpl textstyle'>Once per year</h6>
                                    <p>The highlight of the CIVIT Pioneers Program, this summit is an exclusive
                                        gathering of all Pioneers, CIVIT team members, and special guests. This immersive
                                        event, set in a stunning location, combines workshops, keynote speeches, and
                                        collaborative activities aimed at solidifying the community, sharing achievements, and setting the vision for the year ahead.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className='cardjourney  rounded-3 text-start position-relative h-100'>
                                <div>
                                    <img alt="tools" width={5} className="position-absolute mt-4" src="/assets/images/angle.png" />
                                </div>
                                <div className='p-4'>
                                    <h4 className='text-colorneyblue'>Beta Testing Opportunities</h4>
                                    <h6 className='text-coloropurpl textstyle'>Throughout the Year</h6>
                                    <p>As a Pioneer, you’ll have first access to beta versions of new features and
                                        initiatives within the CIVIT ecosystem. Your feedback will be invaluable, directly
                                        influencing the development process and ensuring that CIVIT meets the true needs and
                                        desires of its community</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className='cardjourney  rounded-3 text-start position-relative h-100'>
                                <div>
                                    <img alt="tools" width={5} className="position-absolute mt-4" src="/assets/images/angle.png" />
                                </div>
                                <div className='p-4'>
                                    <h4 className='text-colorneyblue'>Private Pioneers Circle Access</h4>
                                    <h6 className='text-coloropurpl textstyle'>Ongoing</h6>
                                    <p>An exclusive online platform for CIVIT Pioneers. This is your space to
                                        connect, share insights, and collaborate on projects beyond the mastermind sessions.
                                        The Circle is also where you’ll find resources, updates, and opportunities tailored
                                        specifically for the Pioneers</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className='cardjourney  rounded-3 text-start position-relative h-100'>
                                <div>
                                    <img alt="tools" width={5} className="position-absolute mt-4" src="/assets/images/angle.png" />
                                </div>
                                <div className='p-4'>
                                    <h4 className='text-colorneyblue'>Networking and Collaboration Events</h4>
                                    <h6 className='text-coloropurpl textstyle'>Throughout the Year</h6>
                                    <p>As a Pioneer, you’ll have first access to beta versions of new features and
                                        initiatives within the CIVIT ecosystem. Your feedback will be invaluable, directly
                                        influencing the development process and ensuring that CIVIT meets the true needs and
                                        desires of its community</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className='cardjourney  rounded-3 text-start position-relative h-100'>
                                <div>
                                    <img alt="tools" width={5} className="position-absolute mt-4" src="/assets/images/angle.png" />
                                </div>
                                <div className='p-4'>
                                    <h4 className='text-colorneyblue'>Nation-Building Coliving Experiences</h4>
                                    <h6 className='text-coloropurpl textstyle'>Select Times Throughout the Year</h6>
                                    <p>Participate in groundbreaking coliving experiences at exclusive
                                        destinations. These pop-up events are more than just retreats; they are real-world
                                        applications of the CIVIT living model, offering a blend of collaboration, leisure, and
                                        exploration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className='cardjourney  rounded-3 text-start position-relative h-100'>
                                <div>
                                    <img alt="tools" width={5} className="position-absolute mt-4" src="/assets/images/angle.png" />
                                </div>
                                <div className='p-4'>
                                    <h4 className='text-colorneyblue'>Feedback Loops and Impact Measurement</h4>
                                    <h6 className='text-coloropurpl textstyle'>Ongoing</h6>
                                    <p>Your journey as a Pioneer will be marked by continuous feedback loops
                                        and impact assessments. Through surveys, discussions, and reflection sessions, we will
                                        gauge the effectiveness of our initiatives and your growth, ensuring a mutually enriching
                                        experience.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className='mycardjourney  rounded-3 h-100 '>
                                <div className='p-4 text-center '>
                                    <h4 className='font-500 font-38'>Join us as we forge the <br className="d-none d-lg-block "></br> future,  <span className='dm-italic'> together.</span></h4>

                                </div>
                                <div className='justify-content-center d-flex'>
                                <button onClick={scrolldiv} className="btn mb-4 w-75" type="button" style={{background:'#4640BA',color:'white'}}>
                              <h6 className='font-16 font-700 mb-0'>Embark on the Pioneer Journey</h6>  
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                >
                                    <path
                                        d="M11.0303 3.21967C10.7374 2.92678 10.2626 2.92678 9.96967 3.21967C9.67678 3.51256 9.67678 3.98744 9.96967 4.28033L11.0303 3.21967ZM15.75 9L16.2803 9.53033C16.421 9.38968 16.5 9.19891 16.5 9C16.5 8.80109 16.421 8.61032 16.2803 8.46967L15.75 9ZM9.96967 13.7197C9.67678 14.0126 9.67678 14.4874 9.96967 14.7803C10.2626 15.0732 10.7374 15.0732 11.0303 14.7803L9.96967 13.7197ZM2.25 8.25C1.83579 8.25 1.5 8.58579 1.5 9C1.5 9.41421 1.83579 9.75 2.25 9.75L2.25 8.25ZM9.96967 4.28033L15.2197 9.53033L16.2803 8.46967L11.0303 3.21967L9.96967 4.28033ZM15.2197 8.46967L9.96967 13.7197L11.0303 14.7803L16.2803 9.53033L15.2197 8.46967ZM15.75 8.25L2.25 8.25L2.25 9.75L15.75 9.75V8.25Z"
                                        fill="#fff"
                                    />
                                </svg>
                            </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ContactUs />
        </>
    )
}
