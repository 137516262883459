import React from 'react';
import ContactUs from '../components/ContactUs';


export default function AboutUs(props) {

    const scrolldiv = () => {
        var elem = document.getElementById("ele");
        elem.scrollIntoView();
    }

    return (
        <div>
            <section className='bg-aboutus banner-section'>
                <div className="container">
                    <div className='mt-3 mb-5'>
                        <h6 className='text-colorgreen font-700 font-16 textstyle'>What is Shared Living?</h6>
                        <h1 className='text-shared'>Shared Living at CIVIT</h1>
                        <p className='font-16 '>At CIVIT, shared living extends beyond physical spaces—it's a comprehensive philosophy that
                            integrates all aspects <br /> of life. Our vision embraces a future where communities are not merely
                            places to live but ecosystems for holistic <br /> growth and connection. Here's how we envision each
                            component:</p>
                    </div>
                    <div className='row justify-content-center'>
                        <div className="col-lg-4 col-md-4 col-12 text-lg-end about-hero-section">
                            <div>
                                <div>
                                    <img alt="tools" className="mb-3 rounded-3 about-hero-icon" src="/assets/images/icon1.png" />
                                </div>
                                <h5 className=''>Living</h5>
                                <p className='font-14'>Imagine a world where every home is not just a place of refuge but a vibrant,
                                    sustainable habitat that nurtures well-being and fosters a deep sense of community. Our
                                    vision for living is about creating spaces that are both environmentally sustainable and
                                    deeply connected to the social fabric of the community.</p>
                            </div>
                            <div>
                                <div>
                                    <img alt="tools" className="mb-3 rounded-3 about-hero-icon" src="/assets/images/icon2.png" />
                                </div>
                                <h5>Learning</h5>
                                <p className='font-14'>At CIVIT, learning is a lifelong journey that occurs in every corner of our
                                    community. We envision educational opportunities that are diverse, accessible, and
                                    integrated into everyday activities, enabling continuous growth and innovationAt CIVIT, learning is a lifelong journey that occurs in every corner of our
                                    community. We envision educational opportunities that are diverse, accessible, and
                                    integrated into everyday activities, enabling continuous growth and innovation</p>
                            </div>

                        </div>
                        <div className="col-lg-4 col-md-4 col-12 mt-3">
                            <div>
                                <img alt="tools" className="img-fluid" src="/assets/images/mobileimg.png" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 text-lg-start about-hero-section">
                            <div>
                                <div className='mt-3'>
                                    <img alt="tools" className="mb-3 rounded-3 about-hero-icon" src="/assets/images/icon3.png" />
                                </div>
                                <h6>Working</h6>
                                <p className='font-14' >IWe see a future where workspaces are seamlessly integrated into daily life,
                                    promoting not only productivity but also well-being and creativity. Our vision includes
                                    flexible, technologically advanced environments that support both individual
                                    achievements and collaborative endeavors</p>
                            </div>
                            <div>
                                <div>
                                    <img alt="tools" className="mb-3 rounded-3 about-hero-icon" src="/assets/images/icon4.png" />
                                </div>

                                <h6>Connecting</h6>
                                <p style={{marginBottom:'30px'}} className='font-14'>Our vision for connecting is to cultivate a community where every interaction
                                    enriches lives. We aim to build spaces that encourage meaningful social interactions,
                                    cultural exchanges, and recreational activities, making community the cornerstone of
                                    personal and collective development.</p>
                            </div>

                        </div>
                    </div>
                </div>
                {/* <div className='container-fluid'>
                    <div className="row justify-content-center">
                        <div className='col-md-10'>
                           
                        </div>
                    </div>
                </div> */}
            </section>
            <section className='bg-colorour p-lg-0 p-md-3 p-3'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 text-lg-start align-self-center">
                            <div className='ps-lg-5 '>
                                <h5 style={{marginTop:'15px'}} className='text-coloropurpl textstyle section-heading-upper'>Our vision</h5>
                                <h1 className='text-colorneyblue font-500 section-heading-main'>Our Vision for a <span className="dm-italic">Shared</span> <br className=""></br>Future</h1>
                                <p style={{marginBottom:'25px'}} className='text-colorneyblue section-description'>Our vision at CIVIT is to create a world where shared living is the norm, not the exception. By
                                    integrating living, working, learning, and connecting into a cohesive and sustainable model, we
                                    are setting new standards for how communities operate and thrive. Join us in shaping a future
                                    where each person’s life is fully integrated within their community, creating a richer, more
                                    fulfilling experience for all.</p>
                            </div>
                        </div>
                        <div className="col-md-6 text-lg-end pe-0">
                            <img alt="tools" className="img-fluid" src="/assets/images/larki.png" />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container-fluid p-3">
                    <div className="row align-items-center">
                        <div className="col-md-6 pe-0">
                            <img alt="tools" className="img-fluid" src="/assets/images/imgaboutus.png" />
                        </div>
                        <div className="col-md-6 text-lg-start align-self-center">
                            <div className=''>
                                <h1 className='text-colorneyblue section-heading-main'>Our Mission </h1>
                                <p className='text-colorneyblue section-description'>To harness the power of human connection to solve the world's most pressing problems,
                                    shaping a future where individuals, communities, and the planet collectively thrive. </p>
                            </div>
                            <div className=''>
                                <h1 className='text-colorneyblue section-heading-main'>Our Vision </h1>
                                <p className='text-colorneyblue section-description'> We envision a world where every individual is inspired and empowered to achieve their fullest
                                    potential, transcending barriers and embracing the interconnectedness of all life on our planet.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className='bg-colorour p-lg-0 p-md-3 p-3'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 text-lg-start align-self-center">
                            <div className='ps-lg-5 '>
                                <h5 style={{marginTop:'20px'}} className='text-coloropurpl textstyle section-heading-upper'>MANIFESTO
                                </h5>
                                <h3 className='text-colorneyblue font-500 section-heading-main'>CIVIT is not an <span className="dm-italic">ordinary</span> brand.
                                </h3>
                                <p className='text-colorneyblue section-description'>CIVIT is a movement of like-minded people, formed by the people. It’s a lifestyle. We embody
                                    the values, needs, and dreams that our members care about: community, connectivity,
                                    sustainability, collaboration, inclusivity, adaptability, resilience, and transformative growth.
                                    We are a place for creatives, builders, doers, curious minds, teachers, learners, and global
                                    citizens. We are the place where you come to become the best version of yourself and surround
                                    yourself with other people from around the world doing the same.
                                    We are next to you, with you, around you. It doesn’t matter if you are currently online or
                                    disconnected. We make sure that you are never lonely, even when alone. We give you the tools
                                    to make an impact globally while living locally. We give you the opportunity to transform any
                                    space into a CoSpace, governed by shared values and common goals.
                                    We connect you with people, just like you, so that together you can experience the best
                                    moments of your life. And when you are ready to move on…
                                </p>
                                <h5 className='text-colorneyblue' style={{marginBottom:'20px'}}>We evolve, with you.</h5>
                            </div>
                        </div>
                        <div className="col-md-6 text-lg-end pe-0">
                            <img alt="tools" className="img-fluid" src="/assets/images/girls.png" />
                        </div>
                    </div>
                </div>
            </section>
            <ContactUs />
        </div>
    )
}
