import "./App.css";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/header";
// import AllPatient from './pages/Patients';
import Home from "./pages/home";
import Footer from "./components/footer";
import Pioneers from "./pages/Pioneers";
import AboutUs from "./pages/AboutUs";
// import DragDrop from './pages/DragDrop';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <HeaderWrapper />
        <Routes>
          <Route exact path="/" element={<Home />} />
          {/* <Route exact path="/Patient" element={<AllPatient />} /> */}
          <Route exact path="/Pioneers" element={<Pioneers />} />
          <Route exact path="/AboutUs" element={<AboutUs />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

function HeaderWrapper() {
  const location = useLocation();
  const isWhiteHeader = location.pathname === '/Pioneers' || location.pathname === '/AboutUs';

  return (
    <div className="header-menu d-flex">
      <Header isWhite={isWhiteHeader} />
    </div>
  );
}

export default App;
