import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Header(props) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const location = useLocation();

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleLinkClick = () => {
    if (!isCollapsed) {
      setIsCollapsed(true);
    }
  };

  React.useEffect(() => {
    // Close the navbar when the route changes
    setIsCollapsed(true);
  }, [location]);

  return (
    <div className="container">
      <nav className="navbar navbar-expand-md custom-header pb-0">
        <div className="container-fluid ">
          <Link className="navbar-brand p-0" to="#">
            <img
              alt="header-logo"
              className="img-fluid"
              src={props?.isWhite ? "/assets/images/header-logo-white.png" : "/assets/images/header-logo2.png"}
            />
          </Link>
          <button
            className="navbar-toggler"
            style={{ backgroundColor: props?.isWhite ? "white" : "transparent" }}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
            aria-controls="collapsibleNavbar"
            aria-expanded={!isCollapsed}
            aria-label="Toggle navigation"
            onClick={handleToggle}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className={`collapse navbar-collapse ${props?.isWhite ? 'nav-white' : ""} ${!isCollapsed ? 'show' : ''}`} id="collapsibleNavbar">
            <ul className="ms-auto navbar-nav">
              <li className="nav-item">
                <Link className="nav-link text-capitalize" to="/" onClick={handleLinkClick}>Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-capitalize" to="/Pioneers" onClick={handleLinkClick}>Pioneers</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-capitalize" to="/AboutUs" onClick={handleLinkClick}>About us</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
