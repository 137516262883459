import React from "react";
import { Link } from "react-router-dom";

export default function Footer(props) {
  return (
    <section className="footer-section">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4  footer-logo-col text-start text-white">
            <img alt="footer-logo" className="img-fluid" src="/assets/images/footer-logo.png" />
            <p>Wiring Human Connection</p>

            <ul className="d-flex align-items-center mb-0 gap-2">
              <li>
                <Link
                  className="d-flex rounded-circle align-items-center justify-content-center"
                  to=""
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.6792 6.35337H9.30995V7.66378C9.68891 6.91012 10.6607 6.23295 12.1206 6.23295C14.9192 6.23295 15.5837 7.7332 15.5837 10.4858V15.5837H12.7503V11.1127C12.7503 9.54512 12.3714 8.66112 11.4066 8.66112C10.0686 8.66112 9.51253 9.61382 9.51253 11.1119V15.5837H6.6792V6.35337ZM1.82074 15.4632H4.65408V6.23295H1.82074V15.4632ZM5.05995 3.22324C5.06006 3.46073 5.01296 3.69587 4.92139 3.91499C4.82983 4.13412 4.69562 4.33286 4.52658 4.49966C4.18403 4.8401 3.72036 5.03066 3.23741 5.02949C2.75531 5.02917 2.29271 4.83909 1.94966 4.50037C1.78123 4.333 1.64747 4.13403 1.55607 3.91488C1.46466 3.69573 1.4174 3.46069 1.41699 3.22324C1.41699 2.7437 1.60824 2.2847 1.95037 1.94612C2.29312 1.60694 2.75591 1.41678 3.23812 1.41699C3.7212 1.41699 4.18445 1.60753 4.52658 1.94612C4.86799 2.2847 5.05995 2.7437 5.05995 3.22324Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </li>

              <li>
                <Link
                  className="d-flex rounded-circle align-items-center justify-content-center"
                  to=""
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M11.6663 11.2503H13.7497L14.583 7.91699H11.6663V6.25033C11.6663 5.39199 11.6663 4.58366 13.333 4.58366H14.583V1.78366C14.3113 1.74783 13.2855 1.66699 12.2022 1.66699C9.93967 1.66699 8.33301 3.04783 8.33301 5.58366V7.91699H5.83301V11.2503H8.33301V18.3337H11.6663V11.2503Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </li>
              <li>
                <Link
                  className="d-flex rounded-circle  align-items-center justify-content-center"
                  to=""
                >
                  <img alt="insta-icon" src="/assets/images/insta-icon.svg" />
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-12 col-md-6 col-lg-2 footer-col text-white text-start">
            <h5>Links</h5>
            <ul className="mb-0 d-flex flex-column ">
              <li>
                <Link className=" text-white" to="">Terms & Conditions</Link>
              </li>

              <li>
                <Link className=" text-white" to="">Privacy Policy</Link>
              </li>
              <li>
                <Link className="text-white" to="">Careers</Link>
              </li>
              <li>
                <Link className=" text-white" to="">Our Mission</Link>
              </li>
            </ul>
          </div>

          <div className="col-12 col-md-6  col-lg-3 footer-col text-white text-start">
            <h5>Contact Info</h5>
            <ul className="mb-0 d-flex flex-column ">
              <li>
               <p className="mb-0">1501 Biscayne Blvd,
               <br className=""></br>
               Suite 501, Miami, FL 33132</p>
              </li>

              {/* <li className="d-flex align-items-center gap-2 opacity-100 ">
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
<path d="M13.2487 9.75376C12.3668 9.75376 11.5009 9.61578 10.6803 9.34453C10.2783 9.20734 9.78396 9.33319 9.53856 9.58531L7.91891 10.8084C6.04058 9.80539 4.88356 8.64839 3.8946 6.78359L5.08128 5.20565C5.3896 4.89766 5.50018 4.44775 5.36769 4.02562C5.09535 3.20048 4.95701 2.3347 4.95701 1.45215C4.95705 0.814585 4.43852 0.295898 3.80119 0.295898H1.15585C0.518527 0.295898 0 0.814585 0 1.45211C0 8.7598 5.94331 14.7049 13.2487 14.7049C13.8861 14.7049 14.4046 14.1862 14.4046 13.5487V10.9099C14.4046 10.2724 13.886 9.75376 13.2487 9.75376Z" fill="#00FF6C"/>
</svg>
<p className="mb-0 " style={{opacity: "0.7"}}>+088 (246) 642-27-10</p>
              </li> */}
              <li className="d-flex align-items-center gap-2 opacity-100">
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
<path d="M0.353073 1.65628C2.67207 3.44891 6.74119 6.6027 7.93716 7.58606C8.09771 7.71878 8.26991 7.78625 8.44868 7.78625C8.62708 7.78625 8.79899 7.71941 8.95917 7.58732C10.1562 6.603 14.2253 3.44891 16.5443 1.65628C16.6887 1.54488 16.7107 1.34909 16.5938 1.2129C16.3236 0.898209 15.9207 0.717773 15.4889 0.717773H1.40842C0.976665 0.717773 0.573752 0.898209 0.303571 1.21293C0.186681 1.34909 0.208693 1.54488 0.353073 1.65628Z" fill="#00FF6C"/>
<path d="M16.6925 2.62566C16.5677 2.57264 16.4209 2.59114 16.3171 2.67211C13.7454 4.66208 10.4631 7.21281 9.43044 8.06218C8.85084 8.53974 8.04644 8.53974 7.46548 8.06155C6.36476 7.1563 2.67891 4.2962 0.579568 2.67208C0.475053 2.59111 0.327934 2.57324 0.204179 2.62563C0.0797641 2.67837 0 2.79197 0 2.91716V10.9976C0 11.7064 0.631479 12.2828 1.40807 12.2828H15.4886C16.2652 12.2828 16.8967 11.7064 16.8967 10.9976V2.91716C16.8967 2.79197 16.8169 2.67807 16.6925 2.62566Z" fill="#00FF6C"/>
</svg>
<p className="mb-0" style={{opacity: "0.7"}}>connect@civitlife.com</p>
              </li>
            </ul>
          </div>

          <div className="col-12 col-md-6 col-lg-3 footer-col text-white text-start">
          <h5>Sign up to newsletter</h5>

          <input className="form-control mb-2 bg-transparent text-white" type="text" placeholder="Enter your email"/>
         
          <button
                  className="btn mx-auto w-100"
                  type="button d-flex align-items-center "
                  style={{height: "44px" , fontSize: "14px"}}
                >
                  Signup
                  
                </button>
          </div>

          <div className="col-12 footer-bar">
            <div className="footer-bar-cols">
<p className="desc text-white font-400">© 2024 All Rights Reserved</p>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}
