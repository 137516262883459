import React, { useState } from 'react'
import axios from 'axios';
const ContactUs = () => {
  const [email,setEmail]=useState(null);
  const [name,setName]=useState(null);
  const [subject,setSubject]=useState(null);
  const [body,setBody]=useState(null)
  const [company,setCompany]=useState(null)

  const handleSubmitContactUs = () => {
    const data = JSON.stringify({
        email: email,
        name: name,
        subject: subject,
        body: body,
        company:company
    });

    const config = {
        method: 'post',
        url: 'https://civitemail.canctests.com/', // Ensure this URL is correct
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    axios(config)
        .then((response) => {
          resetForm();
            alert("Your message is Submitted Successfully!");
        })
        .catch((error) => {
            console.error(error);
        });
};
const resetForm = () => {
  setEmail('');
  setName('');
  setSubject('');
  setBody('');
  setCompany('');
};

  return (
    <div>
      <section className="movement-section p-130 text-white">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4 movement-col d-flex flex-column text-start">
              <h1>
                Join the<br className="d-none d-lg-block "></br>{" "}
                <span className="dm-italic text-colorgreen">Movement</span>
              </h1>
              <div className='d-flex mt-3'>
                <div>
                  <img alt="tools" width={30} className=" rounded-3" src="/assets/images/icon.png" />
                </div>
                <div> <p className='ms-3 mb-0'>1501 Biscayne Blvd,
                  <br className=""></br>
                  Suite 501, Miami, FL 33132</p> </div>
              </div>
              <div className='d-flex me-lg-0 me-md-0 me-4' >
                <div>
                  <img alt="tools" width={30} className=" rounded-3" src="/assets/images/icon.png" />
                </div>
                <div> <p className='ms-3 mb-0'>Email <br />
                  connect@civitlife.com</p> </div>
              </div>
              <div className=''>
                <h2 style={{marginTop:'20px'}}> Let's Co-Create! </h2>
                <p className='font-20'>We want to hear from you! Whether you have questions, ideas, or are ready to join us in revolutionizing shared living, your voice matters. Reach out and let's work together to co-create a new living paradigm for all. Fill out the contact form below and become a part of the future of community living with CIVIT.</p>
              </div>

            </div>

            <div className="col-12 col-lg-6 ms-auto movement-form-col position-relative " id="ele">
              <div className="rounded-bg-col d-flex align-items-center ">
                <img
                  alt="rounded-bg"
                  className="img-fluid"
                  src="/assets/images/rounded-bg.svg"
                />
              </div>
              <div className="movement-form bg-white ">
                <h4>Get in Touch with Us</h4>
                <div className="row">
                  <div className="col-12 col-lg-6 movement-form-input text-start">
                    <label>Name*</label>
                    <input value={name} onChange={(e)=>setName(e.target.value)} className="form-control" type="text" />
                  </div>
                  <div className="col-12 col-lg-6 movement-form-input text-start">
                    <label>Company</label>
                    <input value={company} onChange={(e)=>setCompany(e.target.value)} className="form-control" type="text" />
                  </div>

                  <div className="col-12 col-lg-6 movement-form-input text-start">
                    <label>Email*</label>
                    <input value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control" type="text" />
                  </div>

                  <div className="col-12 col-lg-6 movement-form-input text-start">
                    <label>Subject</label>
                    <input value={subject} onChange={(e)=>setSubject(e.target.value)} className="form-control" type="text" />
                  </div>

                  <div className="col-12 movement-form-textarea text-start">
                    <label>Your Message</label>
                    <textarea value={body} onChange={(e)=>setBody(e.target.value)} className="form-control" type="text" />
                  </div>

                  <div className="col-12">
                    <button
                      className="btn mx-auto w-100"
                      type="button"
                      onClick={handleSubmitContactUs}
                    >
                      Send Message
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M11.0303 3.21967C10.7374 2.92678 10.2626 2.92678 9.96967 3.21967C9.67678 3.51256 9.67678 3.98744 9.96967 4.28033L11.0303 3.21967ZM15.75 9L16.2803 9.53033C16.421 9.38968 16.5 9.19891 16.5 9C16.5 8.80109 16.421 8.61032 16.2803 8.46967L15.75 9ZM9.96967 13.7197C9.67678 14.0126 9.67678 14.4874 9.96967 14.7803C10.2626 15.0732 10.7374 15.0732 11.0303 14.7803L9.96967 13.7197ZM2.25 8.25C1.83579 8.25 1.5 8.58579 1.5 9C1.5 9.41421 1.83579 9.75 2.25 9.75L2.25 8.25ZM9.96967 4.28033L15.2197 9.53033L16.2803 8.46967L11.0303 3.21967L9.96967 4.28033ZM15.2197 8.46967L9.96967 13.7197L11.0303 14.7803L16.2803 9.53033L15.2197 8.46967ZM15.75 8.25L2.25 8.25L2.25 9.75L15.75 9.75V8.25Z"
                          fill="#0A1F44"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ContactUs
